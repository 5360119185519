import React, { useState } from 'react'
import emailjs from "@emailjs/browser";


const Contact = () => {

    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [mail, setmail] = useState("");
    const [massages, setMassages] = useState("");

    const nameChange = (e) => {
        const value = e.target.value;
        setName(value);
    };
    const mobileChange = (e) => {
        const value = e.target.value;
        setMobile(value);
    };
    const mailChange = (e) => {
        const value = e.target.value;
        setmail(value);
    };
    const massagesChange = (e) => {
        const value = e.target.value;
        setMassages(value);
    };
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                "service_zerf1uq",
                "template_m7f1atf",
                "#myForm",
                "X5KUzJh_LXeCzDftt"
            )
            .then(
                function (response) {
                    console.log("SUCCESS!", response.status, response.text);
                    if (response.text === "OK") {
                        setName("");
                        setMobile("");
                        setmail("");
                        setMassages("");
                    }
                },
                function (error) {
                    console.log("FAILED...", error);
                }
            );
    };

    return (
        <>

            <div>
                <section className="inner-banner">
                    <h1 className="fw-bold text-center">Contact Us</h1>
                </section>
                <section className="contact-section">
                    <div className="container">
                        <div className="sec-title text-center mb-3" data-aos="fade-up" data-aos-duration={1000}>
                            <span className="title">Get In Touch</span>
                            <h2>Fill in your details and we’ll call you back.</h2>
                            <div className="divider">
                                <span className="fa fa-mortar-board" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 col-md-8">
                                <div className="contact-form p-5" data-aos="zoom-in" data-aos-duration={1000}>
                                    <div className="border-line" />
                                    <h3 className="fw-bold color-orange">Drop Message</h3>
                                    <form className='' id="myForm" onSubmit={sendEmail} action=""
                                        method="post">
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputName">Name</label>
                                            <input className="form-control" id="exampleInputName" placeholder="Enter Name" name='from_name' type="text" value={name} onChange={(e) => nameChange(e)} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputEmail1">Email Address</label>
                                            
                                            <input className="form-control" id="exampleInputEmail1" placeholder="Enter Email" type="email" name="mail" value={mail} onChange={(e) => mailChange(e)} />
                                            <small className="form-text text-muted" id="emailHelp">We'll never share your email with
                                                anyone
                                                else.
                                            </small>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputNumber">Phone Number</label>
                                            <input className="form-control" name="Your Phone" id="exampleInputNumber" placeholder="Enter Number" type='number' value={mobile} onChange={(e) => mobileChange(e)} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputMessage">Message</label>
                                            <input className="form-control" name="message" id="exampleInputMessage" placeholder="Message" type="text" value={massages} onChange={(e) => massagesChange(e)} />
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" id="exampleCheck1" type="checkbox" />
                                            <label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>
                                        </div>
                                        <button className="btn theme-orange border-0 mt-4" type="submit">Send Message</button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 ms-minus">
                                <div className="media p-3 d-flex justify-content-between align-items-center theme-blue mb-3" data-aos="zoom-in" data-aos-duration={1000}>
                                    <div className="media-body text-start">
                                        <h6 className="color-orange fw-bold mb-1">Address</h6>
                                        <p className="mb-0">Acharya Vinoba Bhave Ward No.-15 Hanuman Nagar, Satna, Madhya Pradesh 485001
                                        </p>
                                    </div>
                                    <img className="img-fluid contact-icon" data-aos="zoom-in" data-aos-duration={1050} src="assets/images/icons/location.png" alt="Location" />
                                </div>
                                <div className="media p-3 d-flex justify-content-between align-items-center theme-blue mb-3" data-aos="zoom-in" data-aos-duration={1000}>
                                    <div className="media-body text-start">
                                        <h6 className="color-orange fw-bold mb-1">Email</h6>
                                        <p className="mb-0">sudarshan312317@gmail.com</p>
                                    </div>
                                    <img className="img-fluid contact-icon" data-aos="zoom-in" data-aos-duration={1500} src="assets/images/icons/mail.png" alt="Mail" />
                                </div>
                                <div className="media p-3 d-flex justify-content-between align-items-center theme-blue" data-aos="zoom-in" data-aos-duration={1000}>
                                    <div className="media-body text-start">
                                        <h6 className="color-orange fw-bold mb-1">Contact Number</h6>
                                        <p className="mb-0"> <a href="tel:+91  9893806170">+91 9893806170</a></p>
                                        <p className="mb-0"> <a href="tel:+91  7999108631">+91 7999108631</a></p>
                                    </div>
                                    <img className="img-fluid contact-icon" data-aos="zoom-in" data-aos-duration={1050} src="assets/images/icons/call.png" alt="Call" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <section className="map container">
                <div className="sec-title text-center mb-3" data-aos="fade-up" data-aos-duration={1000}>
                    <h2>Maps</h2>
                    <div className="divider">
                        <span className="fa fa-mortar-board" />
                    </div>
                </div>
                <div style={{ maxWidth: '100%', overflow: 'hidden', color: 'red', width: 1100, height: 300 }}><div id="g-mapdisplay" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=Sudarshan+Public+Hr.Sec.+School++Ward+No.15,+Hanuman+Nagar,+Satna+(Madhya+Pradesh)&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><a className="embed-map-html" href="https://www.bootstrapskins.com/themes" id="grab-map-data">premium bootstrap themes</a><style dangerouslySetInnerHTML={{ __html: "#g-mapdisplay img{max-width:none!important;background:none!important;font-size: inherit;font-weight:inherit;}" }} /></div>
            </section>

            <br />
            <br />
        </>
    )
}

export default Contact